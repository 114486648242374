.v2 ol,
.v2 ul {
    list-style: none;
    padding: 0;
}

.v2 {
    line-height: 1;
}

.v2 #immat {
    background: none;
    border: none;
    height: 52px;
    margin-left: 35px;
    font-size: xx-large;
}

.v2 .vehicule-banner {
    background: linear-gradient(
        270deg,
        #f58100,
        #1e233700,
        #1e233700,
        #1e233700,
        #f58100
    );
    border-radius: 10px 10px 0 0;
    height: 50px;
    margin: 5px;
    color: #fff;
    font-weight: 700;
    width: 100%;
    padding: 5px;
}

.v2 .vehicle-data-wrapper {
    position: fixed;
    z-index: 3;
    bottom: calc(4% + 280px);
    left: 5%;
    max-height: calc(100vh - 4% - 280px);
    background-color: white;
}

.v2 .vehicle-data-header > img {
    max-width: 170px;
}

.v2 .vehicle-data-toggler {
    position: absolute;
}

.v2 .vehicle-data-button {
    border-radius: 5px;
    bottom: calc(4% + 200px);
    left: calc(5% + 465px);
    padding: 0;
    position: fixed;
    z-index: 1;
    width: 95PX;
    height: 60px;
}

.v2 .vehicle-data-button > img {
    width: 90px;
}

.v2 .car-identifier .blog-slider__content > * {
    opacity: 1;
}

.v2 .car-identifier .blog-slider__img > img {
    opacity: 1;
}

.v2 #root {
    width: 100%;
}

.v2 .genart-img {
    cursor: pointer;
    max-height: 80px;
    margin-right: 5px;
}

.v2 .brand-toggle {
    cursor: pointer;
}

.v2 .brand-toggle-active {
    cursor: pointer;
    position: absolute;
    width: 110px;
    height: 96px;
    left: 118px;
    bottom: 117px;
    z-index: 2;
}

.v2 .brand-toggle-active > img {
    opacity: 1;
}

.v2 .genart-carousel {
    width: 22%;
    height: 97%;
    margin-top: 125px;
    border: none;
    text-align: left;
}

.v2 .carousel-control-next,
.v2 .carousel-control-prev {
    cursor: pointer;
    align-items: flex-end;
    padding-bottom: 10px;
}

.v2 .carousel-control-next-icon,
.v2 .carousel-control-prev-icon {
    filter: brightness(0.5);
}

.v2 .slider.responsive {
    overflow: auto;
}

.v2 .selector-carousel > .carousel-inner {
    overflow: visible;
}

.v2 .vehicle-brand {
    display: flex;
}

.v2 .vehicle-brand > .Select {
    flex-grow: 1;
    margin-left: 20px;
    text-align: left;
    color: black;
    font-weight: normal;
}

.v2 .vehicle-brand > .Select img {
    vertical-align: middle;
}

.v2 .iframe-slide {
    position: fixed;
    width: 100%;
    height: 100%;
}

.v2 .iframe-toggle-button {
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 24px;
    padding: 0;
    border: none;
    background-color: transparent;
}

.v2 .Select-menu {
    color: gray;
}

.v2 .selector-carousel {
    height: 100%;
}

.v2 .carousel-indicators {
    position: absolute;
    right: 0px;
    width: 11px;
    left: auto;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    display: block;
    margin: 0;
}

.v2 .carousel-indicators > li {
    border-radius: 10px;
    background: #062744;
    transition: all 0.3s;
    opacity: 0.2;
    width: 11px;
    height: 11px;
    border: none;
    margin: 8px 0;
}

.v2 .carousel-indicators > .active {
    opacity: 1;
    background: #fd3838;
    height: 30px;
    box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);
}

.v2 .blog-slider__img {
    cursor: pointer;
}

.v2 .Select-placeholder {
    text-align: center;
}

.v2 .car-selector-search {
    width: revert;
    flex-grow: 1;
    margin-left: revert;
}

.v2 .part-selector-search > .car-selector-search {
    width: 12em;
}

.v2 .part-selector-search {
    flex-grow: 1;
}

.v2 .part-selector-search > .toggle-exact-search {
    color: black;
}

.v2 .menu-bloc {
    width: 470px;
    height: 270px;
    position: fixed;
    margin: auto;
    background: #fff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 25px;
    padding-right: 20px;
    border-radius: 25px;
    transition: all 0.3s;
    bottom: 4%;
    left: 5%;
    z-index: 4;
    overflow: hidden;
}

.v2 .menu-bloc > .swiper-container {
    margin-left: 35px;
    padding-right: 20px;
    flex-grow: 1;
}

.v2 .menu-bloc .blog-slider__pagination {
    position: absolute;
    top: 100px;
    transform: translate3d(10px, -50%, 0);
}

.v2 .menu-bloc-image {
    position: fixed;
    width: 110px;
    height: 110px;
    box-shadow: 4px 13px 30px 1px rgb(229, 123, 8);
    border-radius: 20px;
    transform: translateX(-85px);
    overflow: hidden;
    /* background-image: linear-gradient(147deg, #000000 0%, #1e2337 74%); */
    z-index: 3;
    bottom: 100px;
    cursor: pointer;
}
.v2 .menu-bloc-image:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    opacity: 0.8;
}

.v2 .menu-bloc-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 20px;
    transition: all 0.3s;
}

.v2 .bloc-slide-title {
    font-size: 24px;
    font-weight: 700;
    color: #0d0925;
    margin-bottom: 20px;
    text-align: center;
}

.v2 .menu-bloc .blog-slider__button {
    display: block;
    margin: auto;
    margin-top: 20px;
}

.v2 .carousel-item > p {
    margin: 0;
}

.v2 .carousel-item > img {
    margin-left: 20px;
    margin-top: 25px;
    width: calc(100% - 40px);
}

.v2 .car-identifier-apps {
    position: fixed;
    bottom: 170px;
    left: 36%;
    width: 52vw;
    height: 17%;
    background: transparent;
    text-align: right;
    z-index: 2;
}

.v2 .blog-slider__text {
    text-align: center;
}

.v2 .navbar-right {
    /* background-color: #1e2337; */
}

.v2 .button-360 {
    position: fixed;
    z-index: 1;
    left: calc(5% + 465px);
    background-color: white;
    color: gray;
}

.v2 .button-360-iam {
    bottom: calc(4% + 150px);
}

.v2 .button-360-oem {
    bottom: calc(4% + 100px);
}

.v2 .Toastify__toast-body pre {
    overflow: hidden;
}

.v2 .qrcode-button {
    position: fixed;
    z-index: 1;
    left: calc(5% + 465px);
    background-color: white;
    color: gray;
}

.v2 .qrcode-button {
    bottom: calc(4% + 50px);
}

.v2 .menu-bloc > .qrcode {
    margin-top: -6px;
}

.v2 .user-profile-bloc {
    top: 100px;
    right: 20px;
}

.v2 .search-bar {
    position: fixed;
    left: 5%;
    bottom: calc(4% + 270px);
    width: 470px;
    z-index: 5;
}

.v2 .carselector-slide-img {
    display: flex;
    justify-content: center;
}
